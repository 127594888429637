import React, { useEffect, useRef } from 'react';
import { keyframes } from '@emotion/react';
import Close from 'components/assets/times.svg';
import styled from '@emotion/styled';
import { theme } from 'styles/theme';
import { routes } from '../routes';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import ReactDOM from 'react-dom';
import Image from 'next/image';
import { DemoButton } from './DemoButton';

const slideIn = keyframes`
  0% {
    top: 90vh;
  }
  100% { top: 0}
`;

const Root = styled.div<{ open: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  animation: ${slideIn} 250ms ease forwards;
`;

const Header = styled.header`
  margin: 3rem;
  display: flex;
  align-items: center;
  position: relative;
  & a {
    display: block;
  }
`;

const Content = styled.nav`
  /* height: 100vh; */
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Links = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LinkItem = styled.li`
  margin: 2rem 0;
  & a {
    color: white;
    text-transform: uppercase;
    font-size: 2rem;
  }
`;

const Icon = styled.i`
  display: block;
  width: 1.7rem;
  & * {
    fill: ${theme.colors.white};
  }
  &:hover * {
    fill: ${theme.colors.darkOrange};
  }
`;

const CustomDemoButton = styled(DemoButton)`
  background-color: ${theme.colors.orange};
  border-radius: 10rem;
  &:active {
    background-color: ${theme.colors.blueGreen};
  }
`;

const Button = styled.button`
  position: absolute;
  display: block;
  position: absolute;
  right: 1rem;
  top: 0.6rem;
  cursor: pointer;
  background-color: transparent;
  border: none;
  height: 3rem;
  cursor: pointer;
  width: 3rem;
  ${{
    [theme.mediaQueries.md]: {
      display: 'none',
    },
  }}
`;

interface Props {
  open: boolean;
  onClose: () => void;
}

export const MobileMenu = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const container = useRef<any>();
  useEffect(() => {
    if (document) {
      container.current = document.getElementById('__next');
    }
  }, []);
  return open && container.current
    ? ReactDOM.createPortal(
        <Root open={open}>
          <Header>
            <Link href="/">
              <a href="/">
                <Image
                  src="/assets/images/logo_blanc.png"
                  alt={t('header.logo')}
                  width={150}
                  height={150 / 3.5}
                  layout="fixed"
                  priority
                />
              </a>
            </Link>
            <Button>
              <Icon onClick={() => onClose()}>
                <Close />
              </Icon>
            </Button>
          </Header>
          <Content>
            <Links>
              {routes(t).map((route, index) => (
                <LinkItem key={index}>
                  <Link href={route.href}>
                    <a href={route.href} onClick={() => onClose()}>
                      {route.label}
                    </a>
                  </Link>
                </LinkItem>
              ))}
            </Links>
            <CustomDemoButton />
          </Content>
        </Root>,
        container.current
      )
    : null;
};
