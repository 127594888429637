import { FlexBox } from 'components/commons/FlexBox';
import React from 'react';
import { Legals } from './Legals';
import { FooterLinks } from './FooterLinks';
import { FooterLogo } from './FooterLogo';
import { Social } from './Social';
import styled from '@emotion/styled';
import { theme } from 'styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.blueGreen};
  padding: 3rem 0;
  ${{
    [theme.mediaQueries.md]: {
      padding: '3rem 4rem',
    },
  }}
`;

const Content = styled(FlexBox)`
  flex-direction: column;
  ${{
    [theme.mediaQueries.md]: {
      flexDirection: 'row',
    },
  }}
`;

export const BottomFooter = () => {
  return (
    <Wrapper>
      <Content justifyContent="space-around" alignItems="center">
        <FooterLogo />
        <FooterLinks />
        <Social />
      </Content>
      <Legals />
    </Wrapper>
  );
};
