import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { DemoButton } from '../Header/DemoButton';
import Link from 'next/link';
import { theme } from 'styles/theme';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-areas:
    'list1'
    'list2'
    'button';
  grid-template-columns: max-content;
  grid-template-rows: 1fr 1fr 1fr;
  ${{
    [theme.mediaQueries.md]: {
      gridTemplateAreas: `'list1 list2'
    'button button'`,
      gridTemplateColumns: 'max-content max-content',
      gridTemplateRows: '1fr 1fr',
    },
  }}
  margin-bottom: 1rem;
`;

const Links = styled.ul<{ area: string }>`
  display: flex;
  flex-direction: column;
  grid-area: ${({ area }) => area};
  align-items: center;
  ${{
    [theme.mediaQueries.md]: {
      alignItems: 'flex-start',
    },
  }}
`;

const LinkItem = styled.li`
  text-transform: uppercase;
  padding: 0.25rem;
  & a,
  a:active {
    color: white;
  }
  & a:hover {
    color: ${theme.colors.darkBlue};
  }
`;

const CutstomDemoButton = styled(DemoButton)`
  grid-area: button;
  text-align: center;
  margin: 1rem 0;
  padding: 0.5rem 2rem;
  border-radius: 7px;
  background-color: ${theme.colors.orange};
  ${{
    [theme.mediaQueries.md]: {
      borderRadius: '10rem',
      margin: 0,
    },
  }}
  &:hover {
    background-color: ${theme.colors.darkBlue};
  }
  & a {
    color: white;
  }
`;

export const FooterLinks = () => {
  const { t } = useTranslation();
  const routesCol1 = (t: any) => [
    {
      label: t('header.mindsapp'),
      href: '/',
    },
    // {
    //   label: t('header.team'),
    //   href: '/team',
    // },
  ];
  const routesCol2 = (t: any) => [
    // {
    //   label: t('header.faq'),
    //   href: '/faq',
    // },
    {
      label: t('header.contact'),
      href: '/contact',
    },
  ];
  return (
    <Root>
      <Wrapper>
        <Links area="list1">
          {routesCol1(t).map((route, index) => (
            <LinkItem key={index}>
              <Link href={route.href}>
                <a href={route.href}>{route.label}</a>
              </Link>
            </LinkItem>
          ))}
        </Links>
        <Links area="list2">
          {routesCol2(t).map((route, index) => (
            <LinkItem key={index}>
              <Link href={route.href}>
                <a href={route.href}>{route.label}</a>
              </Link>
            </LinkItem>
          ))}
        </Links>
        <CutstomDemoButton />
      </Wrapper>
    </Root>
  );
};
