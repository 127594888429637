import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import LinkedIn from 'components/assets/linkedin.svg';
import YouTube from 'components/assets/youtube.svg';
import { theme } from 'styles/theme';

// const LinkedIn = (props) => {
//   return (
//     <svg
//       aria-hidden="true"
//       data-prefix="fab"
//       data-icon="linkedin"
//       className="linkedin_svg__svg-inline--fa linkedin_svg__fa-linkedin linkedin_svg__fa-w-14"
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 448 512"
//       width="1em"
//       height="1em"
//       {...props}
//     >
//       <path
//         fill="#fff"
//         d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
//       />
//     </svg>
//   );
// };

const Root = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0;
  ${{
    [theme.mediaQueries.md]: {
      marginBottom: '3rem',
    },
  }}
`;

const Label = styled.span`
  font-family: 'Magistral-BookItalic';
  font-weight: 300;
  font-size: 1.7rem;
  color: white;
`;

const IconsWrapper = styled.div`
  margin-top: 0.7rem;
  display: flex;
`;

const IconLink = styled.a`
  font-size: 4rem;
  margin: 0 1rem;
  width: 4rem;
  &:hover {
    & * {
      color: ${theme.colors.darkBlue};
    }
  }
  & * {
    color: white;
  }
`;

export const Social = () => {
  const socialIcons = useMemo(
    () => [
      {
        href: 'https://youtube.com/channel/UChdxvzbOGI-twQbf1sJe0ag',
        icon: <YouTube />,
      },
      {
        href: 'https://fr.linkedin.com/company/eukleia',
        icon: <LinkedIn />,
      },
    ],
    []
  );
  const { t } = useTranslation();
  return (
    <Root>
      <Label>{t('footer.followUs')}</Label>
      <IconsWrapper>
        {socialIcons.map(({ href, icon }, key) => (
          <IconLink
            key={key}
            aria-label="Link social network"
            href={href}
            target="_blank"
          >
            {icon}
          </IconLink>
        ))}
      </IconsWrapper>
    </Root>
  );
};
