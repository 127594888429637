import styled from '@emotion/styled';
import React, { ReactElement } from 'react';
import { BottomFooter } from './BottomFooter';

const Root = styled.footer``;

function Footer(): ReactElement {
  return (
    <Root>
      <BottomFooter />
    </Root>
  );
}

export default Footer;
