import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { theme } from 'styles/theme';
import Link from 'next/link';

const Root = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0rem;
  ${{
    [theme.mediaQueries.md]: {
      marginTop: '2rem',
      paddingTop: '2rem',
      borderTop: '1px solid white',
      marginBottom: 0,
    },
  }}
  padding: 1rem;
`;

const LegalItem = styled.span`
  color: white;
  opacity: 0.7;
  display: inline-block;
  margin: 0 0.25rem;
  font-size: 1.2rem;
  & a,
  a:hover,
  a:active,
  a:visited {
    color: ${theme.colors.white};
  }
  & a:hover {
    text-decoration: underline;
  }
  ${{
    [theme.mediaQueries.md]: {
      margin: '0 0.5rem',
      fontSize: '1.5rem',
    },
  }}
`;

export const Legals = () => {
  const { t } = useTranslation();
  const legals = useMemo(
    () => [
      t('footer.rightFull'),
      '-',
      'Mindsapp',
      '-',
      '2021',
      '-',
      <a target="_blank" href="https://vida-communication.fr/">
        Designed by Sophie - VIDA Communication
      </a>,
    ],
    []
  );
  return (
    <Root>
      {legals.map((legal, key) => (
        <LegalItem key={key}>{legal}</LegalItem>
      ))}
    </Root>
  );
};
