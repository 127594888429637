import styled from "@emotion/styled";

export const Article = styled.article<{height?: string, backgroundColor?: string, flexDirection?: string, alignItems?: string, justifyContent?: string, padding?: string}>`
  display: flex;
  flex: 1;
  height: ${({ height }) => height || ''};
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  padding: ${({ padding }) => padding || 0};
  position: relative;
`;
