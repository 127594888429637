import React from 'react';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import { theme } from 'styles/theme';
import Link from 'next/link';
import { Button } from 'components/commons/Button';

const Root = styled(Button)`
  color: ${theme.fontDefault};
  transition: all 150ms ease;
  cursor: pointer;
  white-space: nowrap;
  padding-left: 2rem;
  padding-right: 2rem;
  & a {
    color: white;
    text-transform: none !important;
  }
`;
interface Props {
  className?: string;
}

export const DemoButton = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Root className={props.className}>
      <Link href="/contact">
        <a href="/contact">{t('header.demo')}</a>
      </Link>
    </Root>
  );
};
