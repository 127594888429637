import React from 'react';
import Link from 'next/link';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { Links } from './Links';
import { DemoButton } from './DemoButton';
import { theme } from 'styles/theme';
import { useRouter } from 'next/router';
import { routes } from '../routes';

const Wrapper = styled.div`
  flex: 1;
  display: none;
  ${{
    [theme.mediaQueries.md]: {
      display: 'flex',
    },
  }}
`;

const LinkItem = styled.li<{
  isScrolling: boolean;
  defaultHeader: boolean;
  isSelected: boolean;
}>`
  padding: 1rem;
  margin: 1rem;
  & a {
    text-decoration: none;
    color: ${({ isScrolling, defaultHeader, isSelected }) =>
      isSelected
        ? theme.colors.blueGreen
        : isScrolling || defaultHeader
        ? theme.fontDefault
        : 'white'};
    &:hover {
      color: ${theme.colors.blueGreen};
    }
  }
`;

const CustomDemoButton = styled(DemoButton)`
  background-color: ${theme.colors.orange};
  border-radius: 10rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0;
  &:hover {
    background-color: ${theme.colors.blueGreen};
  }
`;

interface Props {
  isScrolling: boolean;
  defaultHeader: boolean;
}

export const HeaderLink = ({ isScrolling, defaultHeader }: Props) => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Links>
        {routes(t).map((route, index) => (
          <LinkItem
            key={index}
            isScrolling={isScrolling}
            defaultHeader={defaultHeader}
            isSelected={route.href === router.pathname}
          >
            <Link href={route.href}>
              <a href={route.href}>{route.label}</a>
            </Link>
          </LinkItem>
        ))}
      </Links>
      <CustomDemoButton />
    </Wrapper>
  );
};
