import styled from '@emotion/styled';
import { theme } from 'styles/theme';

export const Button = styled.button<{
  fontSize?: string;
  borderRadius?: string;
  backgroundColor?: string;
  color?: string;
  margin?: string;
  padding?: string;
  hover?: string;
  uppercase?: boolean;
}>`
  ${({ uppercase }) => uppercase && ({ textTransform: 'uppercase' })};
  transition: all 150ms ease;
  cursor: pointer;
  font-size: 15px;
  font-family: ${theme.cta.fontFamily};
  padding: 0.75rem;
  border: none;
  background-color: ${({ backgroundColor }) => backgroundColor || theme.colors.darkBlue};
  border-radius: ${({ borderRadius }) => borderRadius || theme.cta.borderRadius};
  color: ${({ color }) => color || 'white'};
  & * {
    color: ${({ color }) => color || 'white'};
  }
  margin: ${({ margin }) => margin};
  ${{
    [theme.mediaQueries.md]: {
      fontSize: theme.cta.fontSize,
      padding: theme.cta.padding
    }
  }}
  &:hover {
    background-color: ${({ hover }) => hover || theme.colors.blueGreen};
  }
  &:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
  align-self: center;
`;
