import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import styled from '@emotion/styled';
import { theme } from 'styles/theme';

const Root = styled.div`
  display: none;
  flex: 1;
  margin-left: 8rem;
  ${{
    [theme.mediaQueries.md]: {
      marginLeft: '8rem',
      display: 'block',
    },
  }}
`;

const ResponsiveLogo = styled.div`
  display: block;
  flex: 1;
  margin: 3rem 0 0 3rem;
  ${{
    [theme.mediaQueries.md]: {
      marginLeft: '8rem',
      display: 'none',
    },
  }}
`;

export const HeaderLogo = () => {
  const { t } = useTranslation();
  return (
    <>
      <Root>
        <Link href="/">
          <a href="/">
            <Image
              src="/assets/pictos/mindsappLogo.svg"
              alt={t('header.logo')}
              width={300}
              height={300 / 3.5}
              layout="fixed"
              priority
            />
          </a>
        </Link>
      </Root>
      <ResponsiveLogo>
        <Link href="/">
          <a href="/">
            <Image
              src="/assets/images/logo_blanc.png"
              alt={t('header.logo')}
              width={200}
              height={200 / 3.52}
              layout="fixed"
              priority
            />
          </a>
        </Link>
      </ResponsiveLogo>
    </>
  );
};
