import styled from '@emotion/styled';

export const Links = styled.ul`
  display: flex;
  align-items: center;
  flex: 1;
  & a {
    text-transform: uppercase;
  }
`;
