import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { theme } from 'styles/theme';
import { onScroll } from 'utils/utils';
import Chevron from 'components/assets/chevron-up.svg';

const slideIn = keyframes`
  0% {
    bottom: -12rem;
  }
  100% { bottom: 2rem}
`;

const slideOut = keyframes`
  0% {
    bottom: 2rem;
  }
  100% { bottom: -12rem}
`;

const IconButtonScroll = styled.div<{
  open: boolean;
  backgroundColor: string;
  hover: string;
}>`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  &:hover {
    background-color: ${({ hover }) => hover};
  }
  bottom: 2rem;
  right: 2rem;
  z-index: 1;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 3px;
  height: 4rem;
  width: 4rem;
  font-size: 1.7rem;
  & * {
    color: white;
    width: 4rem;
    height: 4rem;
  }
  animation: ${({ open }) => (open ? slideIn : slideOut)} 150ms ease forwards;
`;

interface Props {
  open: boolean;
}

export const MainScrollButton = ({ open }: Props) => {
  return (
    <IconButtonScroll
      backgroundColor={theme.colors.orange}
      hover={theme.colors.blue}
      onClick={() => onScroll(null, null)}
      open={open}
    >
      <Chevron />
    </IconButtonScroll>
  );
};
