import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { MainContent } from './MainContent';
import { MainScrollButton } from 'components/commons/MainScrollButton';
import { MobileMenu } from './Header/MobileMenu';

const Root = styled.div<{ shouldBlur: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: all 150ms ease-in-out;
  ${({ shouldBlur }) =>
    shouldBlur && {
      filter: 'blur(20px)',
      overflow: 'hidden',
      transform: 'scale(1.2)',
    }}
`;

interface Props {
  children: any;
  defaultHeader: boolean;
}

const Layout = ({ children, defaultHeader }: Props) => {
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
  });

  const handleScroll = () => {
    const isTop = window.scrollY === 0;
    if (isTop) {
      setIsScrolling(false);
    } else {
      setIsScrolling(true);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    if (mobileOpen && document) {
      const nextRoot = document.getElementById('__next');
      nextRoot.style.overflow = 'hidden';
    } else if (document && !mobileOpen) {
      const nextRoot = document.getElementById('__next');
      nextRoot.style.overflow = 'unset';
    }
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [mobileOpen]);

  return (
    <Root shouldBlur={mobileOpen}>
      <Header
        defaultHeader={defaultHeader}
        isScrolling={isScrolling}
        onOpen={() => setMobileOpen(true)}
      />
      <MainContent>{children}</MainContent>
      <Footer />
      <MainScrollButton open={isScrolling} />
      <MobileMenu onClose={() => setMobileOpen(false)} open={mobileOpen} />
    </Root>
  );
};

export default Layout;
