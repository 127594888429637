import styled from "@emotion/styled";

export const Section = styled.section<{ height?: string, margin?: string; backgroundColor?: string, flexDirection?: string, alignItems?: string, justifyContent?: string, padding?: string; flexWrap?: string }>`
  display: flex;
  flex: 1;
  height: ${({ height }) => height || ''};
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'unset'};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  padding: ${({ padding }) => padding || 0};
  margin: ${({ margin }) => margin || 0};
  position: relative;
`;
