export const onScroll = (hash: string, top?: number) => {
  if (!hash) {
    window.scroll({ top: 0, behavior: 'smooth' });
  } else {
    const element = document.querySelector(hash);
    if (!element) return null;
    const rect = element.getBoundingClientRect(); // get rects(width, height, top, etc)
    window.scroll({
      top: rect.top + window.pageYOffset - top || 110,
      behavior: 'smooth', // smooth scroll
    });
  }
};