import React from 'react';
import styled from '@emotion/styled';
import { HeaderLink } from './HeaderLink';
import { HeaderLogo } from './HeaderLogo';
import { theme } from 'styles/theme';
import Bars from 'components/assets/bars.svg';

const Root = styled.header<{
  defaultHeader?: boolean;
  isScrolling?: boolean;
}>`
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  z-index: 10;
  justify-content: flex-end;
  ${({ isScrolling, defaultHeader }) => ({
    [theme.mediaQueries.md]: {
      position: 'fixed',
      justifyContent: 'unset',
      height: '10rem',
      backgroundColor: isScrolling
        ? 'white'
        : defaultHeader
        ? 'white'
        : 'transparent',
    },
  })}
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  flex: 1;
  & a {
    text-transform: uppercase;
    display: block;
  }
  flex: 1;
`;

const Button = styled.button`
  display: block;
  background-color: transparent;
  border: none;
  height: 4.5rem;
  width: 4.5rem;
  margin-right: 3rem;
  margin-top: 3rem;
  cursor: pointer;
  ${{
    [theme.mediaQueries.md]: {
      display: 'none',
    },
  }}
`;

const Icon = styled.i`
  & * {
    fill: ${theme.colors.white};
  }
  &:hover * {
    fill: ${theme.colors.darkOrange};
  }
`;
interface Props {
  backgroundColor?: string;
  isScrolling: boolean;
  defaultHeader: boolean;
  onOpen: () => void;
}

const Header = ({ isScrolling, defaultHeader, onOpen }: Props) => {
  return (
    <>
      <Root isScrolling={isScrolling} defaultHeader={defaultHeader}>
        <Nav>
          <HeaderLogo />
          <HeaderLink isScrolling={isScrolling} defaultHeader={defaultHeader} />
        </Nav>
        {/* <LanguageSelector
          isScrolling={isScrolling}
          defaultHeader={defaultHeader}
        /> */}
        <Button aria-label="Menu button" onClick={() => onOpen()}>
          <Icon>
            <Bars />
          </Icon>
        </Button>
      </Root>
    </>
  );
};

export default Header;
