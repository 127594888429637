import Image from 'next/image';
import React from 'react';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import { theme } from 'styles/theme';

const Root = styled.div`
  flex: 1;
`;

const ResponsiveImage = styled.div`
  display: block;
  margin-bottom: 2rem;
  ${{
    [theme.mediaQueries.md]: {
      display: 'none',
      marginBottom: 0,
    },
  }}
`;

const DesktopImage = styled.div`
  display: none;
  ${{
    [theme.mediaQueries.md]: {
      display: 'block',
    },
  }}
`;

export const FooterLogo = () => {
  const { t } = useTranslation();
  return (
    <Root>
      <DesktopImage>
        <Image
          src="/assets/images/logo_blanc.png"
          alt={t('header.logo')}
          width={300}
          height={300 / 3.52}
          layout="fixed"
          priority
        />
      </DesktopImage>
      <ResponsiveImage>
        <Image
          src="/assets/images/logo_blanc.png"
          alt={t('header.logo')}
          width={200}
          height={200 / 3.52}
          layout="fixed"
          priority
        />
      </ResponsiveImage>
    </Root>
  );
};
