export const routes = (t: any) => [
  {
    label: t('header.mindsapp'),
    href: '/',
  },
  // {
  //   label: t('header.team'),
  //   href: '/team',
  // },
  // {
  //   label: t('header.faq'),
  //   href: '/faq',
  // },
  {
    label: t('header.contact'),
    href: '/contact',
  },
];