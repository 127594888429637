import { BreakPointsTypes } from 'styles/types';

export const breakpoints: BreakPointsTypes = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1030,
  xlg: 1200,
};

const mediaQueries: BreakPointsTypes = Object.keys(breakpoints).reduce(
  (response, key) => {
    return {
      ...response,
      [key]: `@media (min-width: ${breakpoints[key]}px)`,
    };
  },
  {
    xlg: 0,
    lg: 0,
    md: 0,
    sm: 0,
    xs: 0,
  }
);
export const theme = {
  mediaQueries,
  fontsFamily: {
    magistral: {
      italic: 'Magistral-BookItalic'
    },
    default: {
      name: 'Montserrat',
      weight: '400'
    },
    montserrat: {
      name: 'Montserrat, sans-serif',
      bold: '700',
      medium: '500',
      regular: '400'
    },
    mulish: {
      regular: 'Mulish'
    }
  },
  cta: {
    padding: '10px 25px',
    fontFamily: 'Magistral-BookItalic',
    fontSize: '18px',
    borderRadius: '10px'
  },
  colors: {
    white: '#ffffff',
    black: '#000000',
    lightBlueGreen: '#CEEEEC',
    orange: '#FF9B00',
    darkOrange: '#FF9B00',
    darkBlue: '#0e3e67',
    blue: '#1365AF',
    blueGreen: '#008F83',
    lightBlue: '#005AA3',
    inputError: '#FEEEEB',
    input: {
      error: {
        border: '#FA7F66',
        background: '#FEEEEB'
      },
      success: {
        border: '#0fb1a5',
        background: '#fff'
      }
    },
    background: {
      darkBlue: '#0e3e67',
      error: '#f8d7da'
    },
  },
  fontDefault: '#003057',
  error: '#721c24'
}